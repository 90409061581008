.hint {
  margin: 1px;
}

.last-word-txt {
  color: #7fc733;
}

.submit-btn {
  margin-bottom: 1.35vh;
}

.quitter {
  margin-top: 2vh;
}

.word-button {
  font-size: 1.5vw;
  vertical-align: bottom;
  padding: 0.727vh;
  margin-bottom: 1vh;
}

.picking-name-txt {
  font-size: 1.5vh;
}

.draw-word-txt {
  font-size: 1.35vh;
}

.timer {
  font-size: 1.5vh;
}

.word-pick {
  font-size: 2vh;
}

.player-scores {
  font-size: 3vh;
}

.players {
  font-size: 1.727vh;
}

.player-list-txt {
  font-size: 2vh;
}

.weiners {
  font-size: 2vh;
  color: #a9ac15;
}

.weiner {
  margin-bottom: 4vh;
}

.lobby-welcome {
  font-size: 2.727vh;
}

.lobbys {
  margin-top: 1vh;
}

button {
  font-family: ink free;
  font-weight: bold;
}

.hellosu {
  font-size: 30px;
}

.我真的很喜歡冰淇淋 {
  font-family: 微軟正黑體;
}

.top2 {
  background-color: #e0a8f7;
  text-align: center;
  font-size: 20px;
}

.top1 {
  background-color: #e0a8f7;
  text-align: center;
  font-size: 20px;
  padding-top: 1vh;
}

.top3 {
  background-color: #e0a8f7;
  text-align: center;
  font-size: 20px;
  padding-bottom: 1vh;
}

body {
  font-family: ink free;
  text-align: center;
  background-color: rgb(252 228 255);
}

.SKR {
  font-weight: bold;
  font-size: 70px;
  font-style: italic;
}
.skr-name {
  background-color: rgb(252 228 255);
  height: 100vh;
  text-align: center;
  padding: 30px;
}

.skr-draw {
  position: relative;
}

.display {
  border: 1px solid black;
  background-color: white;
}

.cursor {
  position: absolute;
  top: 0;
  border: 1px solid black;
  transform: translateX(-50%);
  left: 50%;
}

.hide-cursor {
  cursor: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
